import { createWebHashHistory, createRouter } from "vue-router";

import routes from "./routes";

import Cookies from "js-cookie";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // mode: "hash",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  if (authRequired) {
    const userSession = Cookies.get("session");
    if (userSession) {
      let path = routeTo.fullPath;
      let menuString = Cookies.get("menu_set");
      if (menuString) {
        let menuObject = JSON.parse(menuString);
        let menu_selected = [];
        // Step 2: Check if 'filter' array contains 'surat-masuk'
        // Step 1: Retrieve and parse the menu_set from localStorage

        // Step 2: Check if 'filter' array contains 'surat-masuk' and 'link' is 'surat-keluar'
        menu_selected = menuObject.filter(function (item) {
          return item?.link == path;
          // return child_map.indexOf(item) == pos;
        });

        let index_menu_selected = menu_selected[0];
        let role_akses = Cookies.get("session_role_akses_menu");
        if (
          (path.includes("/") || path.includes("/master")) &&
          Cookies.get("session_role_id") == 17
        ) {
          next();
        } else {
          if (role_akses.includes(index_menu_selected?.id) || path.includes("/surat-masuk") || path.includes("/surat-keluar") || path.includes("/digitaliasi") || path.includes("/monitoring")) {
            if (path.includes("administrasi") ) {
              return next("/");
            } else {
              next();
            }
          } else {
            console.log('no-akses');
            return next("/");
          }
        }
      } else {
        next();
      }
    } else {
      return next("/login");
    }
  } else {
    next();
  }
});

export default router;
